<template>
  <b-navbar id="template-header" class="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row" toggleable="lg">
    <div class="text-center navbar-brand-wrapper d-flex align-items-top justify-content-center">
      <router-link class="navbar-brand brand-logo" to="/">
        <!-- <img src="@/assets/images/logo-ailem.png" alt="logo" /> -->
        <img src="@/assets/images/logo-ailem.png" alt="logo" />
      </router-link>
      <router-link class="navbar-brand brand-logo-mini" to="/">
        <!-- <img src="@/assets/images/logo-ailem-mini.png" alt="logo" /> -->
        <img src="@/assets/images/logo-ailem-mini.png" alt="logo" />
      </router-link>
    </div>
    <div class="navbar-menu-wrapper d-flex align-items-center ml-auto ml-lg-0">
      <button class="navbar-toggler navbar-toggler align-self-center d-lg-block" type="button" @click="toggleSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
      <div class="search-field d-none d-md-block">
        <!-- recherche globale -->
        <form action="#">
          <div class="d-flex align-items-center input-group">
            <div class="input-group-prepend bg-transparent">
              <i class="input-group-text border-0 mdi mdi-magnify"></i>
            </div>
            <input type="text" v-model="product" list="search" class="form-control bg-transparent border-0" placeholder="Rechercher un site, produit...">
            <datalist id="search">
              <option v-for="(product, productId) in $store.state.globalSearch.products" :key="productId" :value="product.ulid">{{product.name}}</option>
            </datalist>
          </div>
        </form>
      </div>

      <!-- menu : notification et logout -->
      <b-navbar-nav class="navbar-nav-right ml-auto">
      <!-- notifications -->
        <b-nav-item-dropdown right class="preview-list">
            <template slot="button-content">
              <div class="nav-link count-indicator dropdown-toggle">
                <i class="mdi mdi-bell-outline"></i>
                <!-- nombre de notifications non lu -->
                <span class="count-symbol bg-success" style="width:1rem; height:1rem;color:#fff">
                  {{notifications | isReadOnBoard}}
                </span>
              </div>
            </template>
            <h6 class="p-3 mb-0">Notifications</h6>
            <b-dropdown-item v-for="notification in notifications.slice(0,3)" :key="notification.id"
             class="preview-item" :class="{ unread: !notification.isreadonboard}" @click="ordersDetails(notification)"
             >
             <template v-if="notifications.length>0">
                <div class="preview-thumbnail">
                  <div v-if="notification.object=='reservation'" class="preview-icon bg-success">
                    <i class="mdi mdi-calendar"></i>
                  </div>
                  <div v-else-if="notification.object=='cancellation'" class="preview-icon bg-danger">
                    <i class="mdi mdi-calendar"></i>
                  </div>
                  <div v-else-if="notification.object=='purchase'" class="preview-icon bg-info">
                    <i class="mdi mdi-cart"></i>
                  </div>
                  <div v-else class="preview-icon bg-primary">
                    <i class="fa fa-bell-o"></i>
                  </div>
                </div>
                <div class="preview-item-content d-flex align-items-start flex-column justify-content-center">
                  <h6 class="preview-subject font-weight-normal mb-1">
                    {{
                    notification.object=="reservation"?"Nouvelle réservation"
                    :notification.object=="cancellation"?"Annulation réservation"
                    :"Vous avez une notification"
                    }}
                  </h6>
                  <span v-html="notification.message" class="text-gray ellipsis mb-0">
                  </span>
                </div>
             </template>
             <div v-else>
                <div class="preview-thumbnail">
                  Aucune notification
                </div>
             </div>
             <!-- <div class="preview-thumbnail">
              test
             </div> -->
            </b-dropdown-item>
            <h6 class="p-3 mb-0 text-center border-top pointer" @click="notification()">Voir toutes les notifications</h6>
          </b-nav-item-dropdown>
        <b-nav-item class="nav-logout d-none d-lg-block">
          <a href="javascript:void(0)" @click="logout()">
            <i class="mdi mdi-power"></i>
          </a>
        </b-nav-item>
      </b-navbar-nav>
      <button class="navbar-toggler navbar-toggler-right align-self-center" type="button" @click="toggleMobileSidebar()">
        <span class="mdi mdi-menu"></span>
      </button>
    </div>
  </b-navbar>
</template>

<script>
// import { mapState } from 'vuex'
// const axios= require('axios').default
export default {
  name: 'app-header',
  data(){
    return{
      product:''
    }
  },
  computed: {
    notifications(){
      return this.$store.state.notifications.notifications
    }
  },
  filters:{
    isReadOnBoard(value){
      let filterValue = value.filter(notification => !notification.isreadonboard)
      // let filterValue = value
      let length = filterValue.length
      if (filterValue.length) {
        return length
      }else{
        return 0
      }
      // return filterValue.length
    }
  },
  methods: {
    toggleSidebar: () => {
      document.querySelector('body').classList.toggle('sidebar-icon-only');
    },
    toggleMobileSidebar: () => {
      document.querySelector('#sidebar').classList.toggle('active');
    },
    logout() {
      this.$swal({
        title: "Se déconnecter",
        text: "Voulez-vous quitter l'application?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Se déconnecter',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
          localStorage.removeItem('token')
          localStorage.removeItem('pseudo')
          this.$router.push({name:'authentification'})
        }
      })
    },
    notification() {
      this.$router.push({
        name:"notification"
      })
    },
    ordersDetails(notification){
      if(notification.object !=="cancellation"){
        this.$router.push({
          name:"ordersDetails",
          params:{
            ulid:notification.object_id
          }
        })
        this.readNotification(notification.ulid)
      }else{
        alert('Cette réservation a été supprimée')
      }
    }
  }
}
</script>

<style scoped>
a {
  color: inherit
}

.notification-cart-image{
  width: 50px !important;
  height: 50px  !important;
  border-radius: none !important;
}

/* taille notification panier */
#showCart{
  min-width: 300px !important;
}

.navbar.default-layout-navbar .navbar-brand-wrapper .navbar-brand img {
  /* width: calc(70px - 50px); */
  height: auto;
}
/* notifications */
.unread {
  background-color: #21d0b624;
}
</style>