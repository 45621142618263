import axios from 'axios'
const produits ={
  //data
  state: {
    cart: '',
    products: [],
    sites:''
  },
  // getting the current state value
  getters: {
    user(state) {
      return state.user
    }
  },
   //pour changer le state de vueX
   mutations: {
     SET_PRODUCTS (state, products) {
       state.products = products;
     },
     ADD_PRODUCTS (state, product) {
       state.cart++;
       state.products.push(product)
     },
     REMOVE_PRODUCT_TO_CART (state, index) {
       state.products.splice(index, 1);
       state.cart --
     },
     GET_USER(state) {
       state.isAuthenticated= localStorage.getItem('token'),
       state.roles= localStorage.getItem('roles')
     },
     GET_SITES(state,sites) {
       state.sites= sites
     }
  },
  //Change the value of the state but not change in the action but in the mutations
  //Call the mutation to change the state
  actions: {
    //add products in cart
    ADD_PRODUCTS(context,product) {
      context.commit ('ADD_PRODUCTS',product)
    },

    //get products in store
    getProducts (context) {
      axios.get('products')
      .then( res=>{
        context.commit('SET_PRODUCTS', res.data.result)
        console.log('resProduct in Store', res)
      })
      .catch (err => console.log(err))
    },
    removeProductToCart(context, index) {
      context.commit('REMOVE_PRODUCT_TO_CART', index)
    },
    GetSites(context) {
      axios.get('sites')
       .then(resSites=>{
         context.commit('GET_SITES', resSites.data.result)
       })
       .catch(errSites=>{
         console.log('erreur getSites',errSites.response)
        if (errSites.response.data.message == 'API Token is not valid'){
          window.location.reload()
          console.log('test api token');
        }
        })
    }
  }
}
export default produits;