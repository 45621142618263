<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <li class="nav-item nav-profile">
          <a href="javascript:void(0);" class="nav-link">
            <div class="nav-profile-image">
              <span v-if="$store.state.authentification.webpImage && $store.state.authentification.originalImage">
                <picture>
                  <source class="img-lg rounded-circle mb-3"  :srcset="$store.state.authentification.webpImage" type="image/webp">
                  <img id="profil" class="" :src="$store.state.authentification.originalImage" alt="image" />
                </picture>
              </span>
              <img v-else src="@/assets/images/faces/face1.jpg" alt="profile">
              <span class="login-status online"></span>
            </div>
            <div class="nav-profile-text d-flex flex-column">
              <router-link to="/profil" class="profil">
                <span class="font-weight-bold mb-2">{{$store.state.authentification.pseudo}}</span>
              </router-link>
                
              <span class="text-secondary text-small">{{$store.state.authentification.pseudo}}</span>
            </div>
            <i class="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
          </a>
        </li>

        <!-- Components -->
        <li v-if="isVisible" class="nav-item">
          <componentsSidebar />
        </li>

        <!-- Dashboard -->
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/dashboard">
            <span class="menu-title">Dashboard</span>
            <i class="mdi mdi-home menu-icon"></i>
          </router-link>
        </li>

        <!-- sites et applications -->
        <!-- si dans sites -->
        <!-- <li class="nav-item">
            <span class="nav-link menu-title" v-b-toggle="'siteList'">
              <span class="">
                <router-link to="/website" class="nav-link">
                  Mes Sites
                </router-link>
              </span>
              <i class="menu-arrow"></i>
              <i class="mdi mdi-web menu-icon"></i>
            </span>
          <b-collapse accordion="sidebar-accordion" id="siteList">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item" v-for="(site, siteID) in $store.state.produits.sites" :key="siteID">
                <router-link :to="{ name: 'page', params: { site: site.name, ulidsite: site.ulid }}" class="nav-link">{{site.name}}</router-link>
              </li>
            </ul>
          </b-collapse>
        </li> -->

        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/galerie/">
            <span class="menu-title">Galerie</span>
            <i class="mdi mdi-file-image  menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/compagnies/">
            <span class="menu-title">Société</span>
            <i class=" mdi mdi-domain menu-icon"></i>
          </router-link>
        </li>
        <li class="nav-item" v-on:click="collapseAll">
          <router-link class="nav-link" to="/utilisateur/">
            <span class="menu-title">Utilisateurs</span>
            <i class="mdi mdi-account menu-icon"></i>
          </router-link>
        </li>
        <!-- e-commerce -->
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ecommerce'">
            <span class="menu-title">E-commerce</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart-arrow-down menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ecommerce">
            <ul class="nav flex-column sub-menu">
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/eCommerce/facture/">Facture</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/ecommerce/commande/">Commandes</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/eCommerce/vente/">Vente</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/reservation">Agenda</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/taxes">Taxes</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <!-- Produits -->
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'product'">
            <span class="menu-title">Produits</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart-arrow-down menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="product">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/categories">Catégories</router-link>
              </li>
              <!-- <li class="nav-item">
                <router-link class="nav-link" to="/ecommerce/ensemble/">Pack</router-link>
              </li> -->
              <li class="nav-item">
                <router-link class="nav-link" to="/ecommerce/catalogue/">Catalogue</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/ecommerce/service/">Service</router-link>
              </li>
            </ul>
          </b-collapse>
        </li>
        <!-- mes achats -->
        <!-- <li class="nav-item" v-if="roles ==='ROLE_USER'"> -->


          <!-- MES SERVICES -->
        <!-- <li class="nav-item">
          <span class="nav-link" v-b-toggle="'achat'">
            <span class="menu-title">Mes Services</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart-arrow-down menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="achat">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/achat/facture/">Facture</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/achat/commande/">Commandes</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/achat/service/">Services</router-link>
              </li>
            </ul>
          </b-collapse>
        </li> -->


        <!-- <li v-else-if="roles==='TS'">TS</li> -->
        <!-- <li v-else>tsy misy mihitsy</li> -->

        <!-- Informations -->
        <!-- <li class="nav-item">
          <span class="nav-link"  v-b-toggle="'information'">
            <span class="menu-title">Informations</span>
            <i class="menu-arrow"></i>
            <i class="mdi mdi-cart menu-icon"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="information">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/information/documentation/">Documentation</router-link>
                <router-link class="nav-link" to="/information/FAQ/">FAQ</router-link>
              </li>
            </ul>
          </b-collapse>
        </li> -->
        <!-- logout -->
        <li class="nav-item mobile mt-2 pointer" @click="logout()">
            <span class="menu-title">Se déconnecter</span>
        </li>


      </ul>
    </nav>
  </section>
</template>

<script>
// import siteApp from '../../pages/sitesApp'
// const axios = require('axios').default;
import {mapState, mapGetters} from 'vuex'
import componentsSidebar from '../../components/components-sidebar.vue'

export default {
  name: 'sidebar',
 data () {
    return {
      roles:'TS',
      collapses: [ 
     { show: false },
     { show: false },
     { show: false },
    ],
    section: [],
    componentsMenu: true
    }
  },
  methods: {
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },
    logout() {
      this.$swal({
        title: "Se déconnecter",
        text: "Voulez-vous quitter l'application?",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Se déconnecter',
        cancelButtonText: 'Annuler',
      })
      .then((result) => {
        if (result.value) {
          localStorage.removeItem('token')
          localStorage.removeItem('pseudo')
          this.$router.push({name:'authentification'})
        }
      })
      
    },
  },
  mounted () {
    const body = document.querySelector('body')
    // add class 'hover-open' to sidebar navitem    while hover in sidebar-icon-only menu
    document.querySelectorAll('.sidebar .nav-item').forEach(function (el) {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open')
        }
      })
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open')
        }
      })
    })
    document.querySelector('#sidebar').classList.remove('active')
  },
  watch:{
    $route () {
       document.querySelector('#sidebar').classList.toggle('active');
    }
  },
  components: {
    componentsSidebar
  },
  computed: {
    // ...mapState(['user']),
    ...mapGetters(['user']),
    ...mapState(['user']),
    isVisible(){
      var isfund = false
      if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
        isfund = true
      }
      return isfund
    }
  }

}
</script>

<style scoped>
a:hover {
  color:inherit;
  text-decoration: none;
}
.profil {
  color:#3e4b5b
}
</style>