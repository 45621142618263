import axios from 'axios'   
const globalSearch = {
    state: {
        products:[],
        services:[],
        categories:[]
    },
    getters:{

    },
    mutations:{
        SET_PRODUCTS(state, products){
            state.products = products
        }
    },
    actions:{
        getProducts(context){
            axios.get('products')
            .then(resGetProducts => {
                context.commit('SET_PRODUCTS', resGetProducts.data.result)
                console.log(resGetProducts)
            })
            .catch(errGetProducts=> console.error(errGetProducts))
        }
    }
}
export default globalSearch