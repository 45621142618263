<template>
<div>
  <section class="main-view">
    <!-- pageLoader -->
    <!-- <pageLoader /> -->
    
    <div class="container-scroller">
      <Header/>
      
      
      <div class="container-fluid page-body-wrapper">
        
        <Sidebar/>
        <vue-snotify></vue-snotify>
        <div class="main-panel">
          <!-- bandeau haut de page-->
          <div v-if="$route.name=='dashboard'" class="privacyPolicy row">
            <div class="col-md-12" v-if="!isHidden">
                <b-alert show>
                   Politique de confidentialité et Cookies. <br>
                  Ce site utilise des cookies fonctionnels et des cookies Google Analytics. <br>
                Les cookies fonctionnels sont indispensables au fonctionnement du site.
                  Les cookies Google Analytics sont destinés à des fins de mesure d'audience, nous aident à identifier le contenu qui vous intéresse le plus ainsi qu'à repérer certains dysfonctionnements du site.
                  En continuant à naviguer, vous nous autorisez à déposer ces cookies.
                  <a href="https://ailem.fr/mentions">En savoir plus</a>
                              <hr>
                    <div class="row">
                      <div class="col-md-5 offset-md-7">
                        <button class="btn btn-secondary" v-on:click="isHidden = true">Refuser</button>
                        <button class="btn btn-primary" v-on:click="isHidden = true">Accepter</button>
                      </div>
                    </div>
                </b-alert>
            </div>
          </div>
          <!-- fin bandeau haut de page -->
          <div class="content-wrapper">
            <router-view></router-view>
            <!-- <componentsSidebar/> -->
          </div> <!-- content wrapper ends -->
          <Footer/>
        </div> <!-- main panel ends -->
      </div> <!-- page-body-wrapper ends -->
    </div>
  </section>
</div>
</template>

<script>
  import Header from "./partials/Header";
  import Sidebar from "./partials/Sidebar";
  import Footer from "./partials/Footer";
  // import componentsSidebar from '../components/components-sidebar.vue';
  // import unauthentified from "./../components/unauthentifiedPage"
  // import pageLoader from "./../components/pageLoader"
  export default {
    name: "layout",
    components: {
      Header,
      Sidebar,
      Footer,
      // componentsSidebar
      // unauthentified,
      // pageLoader
    },
    data () {
      return {
        isHidden: false,
        logged: null
      }
    },
    computed:{
      isVisible(){
        var isfund = false
        if (this.$route.name == 'test' || this.$route.name == 'test2'|| this.$route.name == 'test3' || this.$route.name=='page-preview'){
          isfund = true
        }
        return isfund
      }
    },
    created () {
      // this.logged= localStorage.getItem('token')
      this.$store.dispatch('getNotifications')
      this.$store.commit('GET_USER')
      this.$store.dispatch('GetSites')
      this.$store.dispatch('getProducts')
    }
  }
</script>