import Vue from 'vue'
import Router from 'vue-router'

import layout from '../layout'
// import horizontalLayout from '../horizontal-layout'
// import store from '../store/modules/authentification';


Vue.use(Router)

const router = new Router({
  linkExactActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  mode: 'history',
  base: '/',
  // début des routes du routage
  routes: [
    {
      path: '',
      component: {
        render(c) { return c('router-view')}
      },
        children: [
          {
            path: '',
            name: 'authentification',
            component: () => import('@/pages/authentification/authentification')
          },
          {
            path: '/login2',
            name: 'login2',
            component: () => import('@/pages/authentification/login2')
          }
        ]
    },
    {
      path: '/auth-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        // {
        //   path: '/authentification',
        //   name: 'authentification',
        //   component: () => import('@/pages/authentification/authentification')
        // },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import('@/pages/authentification/lock-screen')
        },
        {
          path: '/change-password',
          name: 'change-password',
          component: () => import('@/pages/authentification/change-password')
        }
      ]
    },
    // Page d'erreur
    {
      path: '/error-pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          name: 'error-404',
          component: () => import('@/pages/errorPage/error-404')
        },
        {
          path: 'error-500',
          name: 'error-500',
          component: () => import('@/pages/errorPage/error-500')
        }
      ]
    },
    {
      path: '/inscription',
      name: 'inscription',
      component: () => import ('@/pages/authentification/inscription')
    },
    // page de présentation
    {
      path:'/mock-up',
      name:'mock-up',
      component: () => import('@/pages/sites/preview/page-preview-d')
    },

    // Aperçu de la page
    {
      path: '/preview',
      component:layout,
      children: [
        {
          path:'',
          name: 'preview',
          component: () => import ('@/pages/metadata/preview')
        },
        {
          path:'/preview-blog',
          name: 'preview-blog',
          component: () => import ('@/pages/metadata/preview-blog')
        },
        {
          path:'/preview-section-carousel',
          name: 'preview-section-carousel',
          component: () => import ('@/pages/metadata/section-carousel')
        }
      ]
    },
    {
      path: '/dashboard',
      component: layout,
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/pages/dashboard/dashboard')
        },
        
      ]
    },
    {
      path: '/reservation',
      component: layout,
      children: [
        {
          path: '',
          name: 'reservation',
          component: () => import('@/pages/e-commerce/reservation')
        },
      ]
    },
    {
      path: '/reservation/edit/:ulid',
      component: layout,
      children: [
        {
          path: '',
          name: 'edit-reservation',
          component: () => import('@/pages/e-commerce/reservation')
        },
      ]
    },
    {
      path: '/blockdate',
      component: layout,
      children: [
        {
          path: '',
          name: 'blockdate',
          component: () => import('@/pages/e-commerce/blockdate')
        },
      ]
    },
    {
      path: '/taxes',
      component: layout,
      children: [
        {
          path: '',
          name: 'taxes',
          component: () => import('@/pages/e-commerce/taxes')
        },
      ]
    },
    {
      path: '/compagnies',
      component: layout,
      children: [
        {
          path: '',
          name: 'company',
          component: () => import('@/pages/company/company')
        },
        {
          path: ':ulid',
          name: 'companyDetails',
          component: () => import('@/pages/company/companyDetails')
        },
        {
          path: '/ajouter',
          name: 'addCompany',
          component: () => import('@/pages/company/addCompany')
        },
        {
          path: '/edit/:ulid',
          name: 'editCompany',
          component: () => import('@/pages/company/addCompany')
        }
      ]
    },
    {
      path: '/website',
      component:layout,
      children:[
        {
          path:'',
          name: 'sitesApp',
          component: () => import('@/pages/sites/website')
        },
        {
          path:'edit-text',
          name: 'edit-text',
          component: () => import('@/pages/test/edit-text')
        },
        {
          path:'edit-carousel-meb',
          name: 'edit-carousel-meb',
          component: () => import('@/pages/test/edit-carousel-meb')
        },
        {
          path:'edit-shop-meb',
          name: 'edit-shop-meb',
          component: () => import('@/pages/test/edit-shop-meb')
        },
        // Pages d'un site
        {
          path:'/:site/:ulidsite/pages',
          name: 'page',
          component: () => import('@/pages/sites/pages')
        },
        // liste des sections d'une page
        {
          path:'/:site/:page/section',
          name: 'section',
          component: () => import('@/pages/sites/section')
        },
        {
          path:'/section',
          name: 'sectionBasic',
          component: () => import('@/pages/sites/section')
        },
        {
          path: '/carousel/:ulid',
          name: 'add-section-carousel',
          component: () => import ('@/pages/sites/sections/add-section-carousel')
        },
        {
          path: '/text/:ulid',
          name: 'add-section-text',
          component: () => import ('@/pages/sites/sections/add-section-text')
        },
        {
          path: '/card/:ulid',
          name: 'add-section-card',
          component: () => import ('@/pages/sites/sections/add-section-card')
        },
        {
          path: '/cardTitleDescription/:ulid',
          name: 'add-section-card-icon-top',
          component: () => import ('@/pages/sites/sections/add-section-card-icon-top')
        },
        {
          path: '/cardIconTop/:ulid',
          name: 'add-section-card-title-description',
          component: () => import ('@/pages/sites/sections/add-section-card-title-description')
        },
        {
          path: '/horizontalBanner/:ulid',
          name: 'add-section-horizontal-banner',
          component: () => import ('@/pages/sites/sections/add-section-horizontal-banner')
        },
        
        // information d'une section
        {
          path: 'site/page/section/info',
          name: 'sectionInfo',
          component: () => import('@/pages/sites/sectionInfo')
        },
        
        // Ajout slide carousel
        {
          path: '/site/page/section/slide',
          name: 'slide',
          component: () => import('@/pages/sites/ui/slide')
        },
        // information des pages
        {
          path: 'site_page',
          name: 'sitePage',
          component: () => import ('@/pages/sites/site_page')
        },
        {
          path: 'pages',
          name: 'pages',
          component: () => import ('@/pages/sites/pages')
        }
      ]
    },
    {
      path: '',
      component: layout,
      children: [
        // page-preview
        {
          path:'/sections/pages/:ulid',
          name: 'page-preview',
          component: () => import ('@/pages/sites/preview/page-preview-d')
        },
      ]
    },
    {
      path: '/pageLoader',
      component: layout,
      children: [
        {
          path:'',
          name:'pageLoadercontent',
          component: () => import ('@/pages/pageLoader')
        }
      ]
    },
    // test
    {
      path: '/test',
      component: layout,
      children: [
        {
          path: '',
          name: 'test',
          component: () => import('@/pages/test/test' )
        },
      ]
    },
    // {
    //   path: '/test2',
    //   component: layout,
    //   children: [
    //     {
    //       path: '',
    //       name: 'test2',
    //       component: () => import('@/pages/sites/preview/page-preview-edena' )
    //     },
    //   ]
    // },
    {
      path: '/test3',
      component: layout,
      children: [
        {
          path: '',
          name: 'test3',
          component: () => import('@/pages/sites/preview/page-preview-d' )
        },
      ]
    },
    {
      path: '/meb-home',
      component: layout,
      children: [
        {
          path: '',
          name: 'meb-home',
          component: () => import('@/pages/sites/preview/meb/meb-home' )
        },
      ]
    },
    {
      path: '/metadata',
      component: layout,
      children: [
        {
          path: '',
          name: 'metadata',
          component: () => import('@/pages/test/metadata' )
        },
      ]
    },
    {
      path: '/construction',
      component: layout,
      children: [
        {
          path: '',
          name: 'construction',
          component: () => import('@/pages/construction' )
        },
      ]
    },
    // page des notifications
    {
      path: '/notification',
      component: layout,
      children: [
        {
          path: '',
          name: 'notification',
          component: () => import('@/pages/notification' )
        },
      ]
    },
    // fin test
    {
      path: '/galerie',
      component: layout,
      children: [
        {
          path: '',
          name: 'gallery',
          component: () => import('@/pages/gallery/gallery' )
        },
        {
          path: 'add-image/:entitytype/:ulid',
          name: 'add-image',
          component: () => import('@/pages/gallery/add-image' )
        }
      ]
    },
    {
      path: '/utilisateur',
      component: layout,
      children: [
        {
          path: '',
          name: 'user',
          component: () => import ('@/pages/users/user')
        },
        {
          path: 'ajouter',
          name: 'addUser',
          component: () => import ('@/pages/users/addUser')
        },
        {
          path: 'edit/:ulid',
          name: 'editUser',
          component: () => import ('@/pages/users/addUser')
        }
      ]
    },
    {
      path: '/achat',
      component: layout,
      children: [
        {
          path: '',
          name: 'purchase',
          component: () => import('@/pages/achats/purchase')
        },
        {
          path: 'facture',
          name: 'achatFacture',
          component: () => import ('@/pages/achats/achatFacture')
        },
        {
          path: 'commande',
          name: 'achatCommande',
          component: () => import ('@/pages/achats/achatCommande')
        },
        {
          path: 'service',
          name: 'achatService',
          component: () => import ('@/pages/construction')
          // component: () => import ('@/pages/achats/achatService')
        }
      ]
    },
    {
      path:'/information',
      component: layout,
      children: [
        {
          path: 'documentation',
          name: 'documentation',
          component: () => import ('@/pages/construction')
          // component: () => import ('@/pages/informations/documentation')
        },
        {
          path: 'FAQ',
          name: 'FAQ',
          component: () => import ('@/pages/construction')
          // component: () => import ('@/pages/informations/FAQ')
        }
      ]
    },
    {
      path: '/eCommerce',
      component: layout,
      children: [
        {
          path: 'facture',
          name: 'facture',
          component: () => import ('@/pages/e-commerce/facture')
        },
        {
          path: 'facture/:ulid',
          name: 'detailsFacture',
          component: () => import ('@/pages/e-commerce/detailsFacture'),
          props: true
        },
        {
          path: 'commande',
          name: 'commande',
          component: () => import ('@/pages/e-commerce/commande')
        },
        {
          path: 'details/:ulid',
          name: 'ordersDetails',
          component: () => import ('@/pages/e-commerce/ordersDetails')
        },
        {
          path: 'ensemble',
          name: 'ensemble',
          component: () => import ('@/pages/e-commerce/ensemble')
        },
        {
          path: 'catalogue',
          name: 'catalogue',
          component: () => import ('@/pages/products/catalogue')
        },
        {
          path: 'catalogue-disabled',
          name: 'catalogue-disabled',
          component: () => import ('@/pages/products/catalogue-disabled')
        },
        {
          path: 'catalogue/:ulid',
          name: 'catalogueDetails',
          component: () => import ('@/pages/products/CatalogueDetails')
        },
        {
          path: 'editCatalogue/:ulid',
          name: 'editCatalogue',
          component: () => import ('@/pages/products/addCatalogue')
        },
        {
          path: 'addCatalogue/',
          name: 'addCatalogue',
          component: () => import ('@/pages/products/addCatalogue')
        },
        {
          path: 'vente',
          name: 'ecommerceVente',
          component: () => import ('@/pages/e-commerce/vente')
        },
        {
          path: 'agenda',
          name: 'agenda',
          component: () => import ('@/pages/e-commerce/agenda')
        },
        {
          path: 'service',
          name: 'service',
          component: () => import ('@/pages/products/service')
        },
        {
          path: 'service-archive',
          name: 'service-archive',
          component: () => import ('@/pages/products/service-archive')
        },
        {
          path: 'addService',
          name: 'addService',
          component: () => import ('@/pages/products/addService')
        },
        {
          path: 'editService/:ulid',
          name: 'editService',
          component: () => import ('@/pages/products/addService')
        },

      ]
    },
    {
      path:'/categories',
      component: layout,
      children: [
       {
        path:'',
        name: 'category',
        component: () =>import ('@/pages/products/category')
      },
      {
        path: ':ulidCategory',
        name: 'categoryInfo',
        component: () => import ('@/pages/products/categoryInfo')
      },
      {
        path:'addCategory',
        name: 'addCategory',
        component: () =>import ('@/pages/products/addCategory')
      },
      {
        path:'editCategory/:ulid',
        name: 'editCategory',
        component: () =>import ('@/pages/products/addCategory')
      }
      ]
    },
    {
      path:'/panier',
      component: layout,
      children: [
        {
          path:'',
          name:'cart',
          component: () =>import ('@/pages/cartItem')
        }
      ]
    },
    //profil de l'utilisateur
    {
      path:'/profil',
      component: layout,
      children: [ {
        path: '',
        name:'profil',
        component: () => import ('@/pages/profil')
      }
      ]
    },
    {
      path:'/blank',
      component: layout,
      children: [ {
        path: '',
        name:'blank',
        component: () => import ('@/pages/blank')
      }
      ]
    },
    //redirection
    {
      path: '*',
      redirect: '/error-404',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: 'error-404',
          component: () => import('@/pages/errorPage/error-404')
        }
      ]
    },
    // {
    //   path: '',
    //   redirect: '/authentification'
    // }
    
    // fin des routes de aile'm
  ]
})

//global redidrection
// router.beforeEach((to, from, next) => {
//   console.log(" isAuthenticated : "+store.state.isAuthenticated)
//   if (to.name !== 'authentification' && !store.state.isAuthenticated) next({ name: 'authentification' })
//   else next()
// })

export default router