import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2'
import ProductZoomer from 'vue-product-zoomer'
import VueFilterDateFormat from 'vue-filter-date-format';
import AOS from 'aos'

import axios from 'axios';
import VueAxios from 'vue-axios';
import store from './store/index';
import Snotify, { SnotifyPosition} from 'vue-snotify'
import Vuelidate from 'vuelidate'
import checkView from 'vue-check-view'
import 'aos/dist/aos.css'
AOS.init()

import VCalendar from 'v-calendar';
import device from "vue-device-detector"


axios.defaults.withCredentials = true;
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(ProductZoomer)
Vue.use(Vuelidate)
Vue.use(VueFilterDateFormat)
Vue.use(AOS)
Vue.use(checkView)
Vue.use(VCalendar, {
  //hanaovana "vc-date-picker"
  componentPrefix: 'vc'
});

Vue.config.productionTip = false
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  }
}
Vue.use(Snotify, options)
Vue.use(device)

//global configuration for axios
//variabilisé selon l'utilisateur connecté
axios.defaults.baseURL="https://api.monechappeebelle.fr/";
// axios.defaults.baseURL="https://be-monechapeebelle.comolapapaya.com/";
axios.defaults.headers.get['X-AUTH-TOKEN']= localStorage.getItem('token');
axios.defaults.headers.put['X-AUTH-TOKEN']= localStorage.getItem('token');
axios.defaults.headers.delete['X-AUTH-TOKEN']= localStorage.getItem('token');
axios.defaults.withCredentials = true

// axios.interceptors.response.use(function (response) {
  //   return response
  // }, function (error) {
    //   if (error.response.status === 401) {
      //     router.push('/')
      //   }
      //   return Promise.reject(error)
      // })
      
      axios.interceptors.response.use(function (res) {
        console.log(res);
        if(res.status === 201){
          // Vue.prototype.$snotify.info("Ajouter avec success");
        }
        else if(res.status === 204){
          Vue.prototype.$snotify.info("Modifié avec succès");
   }else{
    // Vue.prototype.$snotify.info("Success!!");
  }
  return res
},
function (error) {
  Vue.prototype.$snotify.error(error.message);
  if (error.response.status === 401) {
    Vue.prototype.$snotify.error(error.message);
    //clear session
    localStorage.clear()
    router.push('/')
  }else{
    Vue.prototype.$snotify.error(error.message);
  }
  if(error.response.status >= 500){
    router.push('/error-pages/error-500')
  }
  return Promise.reject(error)
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// accès au state du store pour pouvoir récupérer ensuite les liens des requêtes de l'api
// console.log("store", window.app.__vue__.$store.state.authentification.pseudo);