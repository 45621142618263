<template>
    <section>
        <button class="btn btn btn-gradient-success btn-rounded btn-icon btn-secondary btn-float" v-b-toggle.components-sidebar>
            <i class="fa fa-database"></i>
        </button>
        <!-- <span class="nav-link" v-b-toggle.components-sidebar>
            <span class="menu-title">Composants</span>
            <i class="mdi mdi-buffer menu-icon"></i>
        </span> -->
        <b-sidebar id="components-sidebar" title="Composants" left shadow="">
            <div class="px-2">
                <b-form-group>
                <b-form-input v-model="search" type="text" placeholder="Recherche"></b-form-input>
                </b-form-group>
            </div>
            <div v-if="search==''" class="px-3 py-2">
            <div class="drag" v-for="(element,elementIndex) in elements" :key="elementIndex">
                <b-card-header  header-tag="header" role="tab">
                    <span v-b-toggle="'accordion' + elementIndex">
                        {{element.elementCategory}}
                    </span>
                </b-card-header>
                <b-collapse :id="'accordion' + elementIndex" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                        <draggable
                        class="row"
                            :list="element.elementList"
                            :group="{ name: 'composant', pull: 'clone', put: false }"
                        >  
                        <div class="col-md-6 p-1 my-2" v-for="layout in element.elementList" :key="layout.id">
                            <div class="draggable-item p-1">
                                <img v-if="layout.elementImage" style="width:100%" :src="require(`../assets/images/import/element/${layout.elementImage}`)" alt="">
                                <img v-else src="@/assets/images/import/default-image.jpg" style="width:100%" alt="">
                                <!-- <div class="text-muted">{{image}}</div> -->
                                <p class="text-center">{{layout.element}}</p>
                            </div>
                        </div>
                        </draggable>
                    </b-card-body>
                </b-collapse>
            </div>
        </div>
        <div v-else class="px-4 py-2">
            <div v-for="element in elements" :key="element.id">
                <!-- <h4 class="text-center">{{element.elementCategory}}</h4> -->
                <draggable
                class="row"
                    :list="element.elementList"
                    :group="{ name: 'composant', pull: 'clone', put: false }"
                >  
                    <div class="col-md-6 my-2 px-2" v-for="layout in searchElement(element.elementList)" :key="layout.id">
                        <div class="p-1 draggable-item">
                            <img v-if="layout.elementImage" style="width:100%" :src="require(`../assets/images/import/element/${layout.elementImage}`)" alt="">
                            <img v-else src="@/assets/images/import/default-image.jpg" style="width:100%" alt="">
                            <p class="text-center">{{layout.element}}</p>
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
        </b-sidebar>
    </section>
</template>

<script>
import elementAddSection from "../pages/json/element-add-section.json"
import draggable from "vuedraggable";

export default {
    components: {
        draggable
    },
    display: "Clone",
    order: 2,
    data () {
        return {            
            elements: elementAddSection.result,
            search:''
        }
    },
    props: {

    },
    methods: {
        searchElement(elements){
            const regex = new RegExp(this.search.toLowerCase());
            let result = []
            elements.forEach(el=> {
                if(regex.test(el.element.toLowerCase())){
                    result.push(el)
                }
            })
            return result
        }
    },
    mounted () {
        console.log(this.elements);
    },
    created() {
  },
}
</script>
<style scoped>
.draggable-item {
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.13);
}
.draggable img {
    pointer-events: none;
    user-select: none;
}
.btn-float{
    position: absolute;
    z-index: 3000;
    bottom: 5rem;
    right: 3rem;
}
</style>